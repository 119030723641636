<template>
  <div class="common-module relative">
    <div class="module-content" style="height:100%">
      <div style="width: 60%; margin-left: -10px">
        <c-search
          v-model="form"
          labelWidth="10px"
          :searchItems="searchItems"
          @search="handleSearch"
          @reset="handleReset"
        >
        </c-search>
      </div>
      <div class="table-wrapper" style="height: calc(100% - 59px)">
        <c-table
          :index="true"
          :data="pageData"
          :tableColumns="tableColumns"
          v-loading="pageLoading"
          :stripe="true"
          :pagination="form"
          @change="pageGet"
        >
          <template v-slot:custom="{ row }">
            <span
              class="opt-detail"
              @click="$router.push({path:'/single/traffic/capture/detail/' + row.id,query:{time:row.checkTime}})"
              >详情</span
            >
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import Configs from "./config/index";
export default {
  data() {
    let { searchItems, tableColumns } = Configs(this);
    return {
      searchItems,
      tableColumns,
      form: {
        pageSize: 10,
        pageNum: 1,
        currentPage: 1,
        total: 0,
        card: "",
        date: [],
      },
      pageLoading: false,
      pageData: [],
    };
  },
  created() {
    this.pageGet()
  },
  methods: {
    pageGet() {
      let params = {
        startTime:this.form.date[0],
        endTime:this.form.date[1],
        carNum:this.form.card,
        pageNum:this.form.pageNum,
        pageSize:this.form.pageSize
      }
      if (this.form.date[0]){
        params.startTime = this.form.date[0] + ' 00:00:00'
      }
      if (this.form.date[1]){
        params.endTime = this.form.date[1] + ' 23:59:59'
      }
      this.$http.get("/car/carOverloadPage",{params:params}).then(res=>{
        if(res.success){
          if (res.data){
            this.pageData = res.data.list
            this.form.total = res.data.total
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleReset() {
      this.form.card = ''
      this.form.date = []
      this.pageGet()
    },
    handleSearch() {
      this.pageGet()
    },
  },
};
</script>
