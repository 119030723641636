export default function Configs() {
    const searchItems = [
        {
            label: "",
            span: 16,
            placeholder: "",
            model: "date",
            type: "daterange",
            labelWidth:0,
        },
        {
            label: "",
            span: 8,
            placeholder: "车牌号",
            type: "input",
            model: "card",
        },
    ]
    const tableColumns = [
        {
            label: "检测时间",
            prop: "checkTime",
            align: "center",
            minWidth: 140,
            show: true
        },
        {
            label: "车牌",
            prop: "carNum",
            align: "center",
            show: true
        },
        {
            label: "车牌颜色",
            prop: "carColor",
            align: "center",
            show: true
        },
        {
            label: "车重(kg)",
            prop: "grossWeight",
            align: "center",
            show: true
        },
        {
            label: "车速(km/h)",
            prop: "speed",
            align: "center",
            show: true
        },
        {
            label: "操作",
            name: 'custom',
            align: "center",
            show: true
        },
    ];
    return {
        searchItems,
        tableColumns,
    };
}
